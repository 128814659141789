<template>
  <b-modal centered modal-class="dispatch_report_model"
           id="modal-dispatch-report-v2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('modal-dispatch-report-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0453 1.25584C10.3246 0.950019 10.3032 0.47563 9.99738 0.196264C9.69155 -0.0831022 9.21716 -0.0616547 8.9378 0.244168L5.57658 3.9237L2.21537 0.244168C1.936 -0.0616547 1.46161 -0.0831022 1.15579 0.196264C0.849966 0.47563 0.828519 0.950019 1.10788 1.25584L4.56076 5.03572L1.10788 8.8156C0.828519 9.12142 0.849966 9.59581 1.15579 9.87517C1.46161 10.1545 1.936 10.1331 2.21537 9.82727L5.57658 6.14774L8.9378 9.82727C9.21716 10.1331 9.69155 10.1545 9.99738 9.87517C10.3032 9.59581 10.3246 9.12142 10.0453 8.8156L6.5924 5.03572L10.0453 1.25584Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36" fill="none">
          <path d="M16.7854 4.01392H4.72984C3.9305 4.01392 3.16391 4.33145 2.59869 4.89666C2.03348 5.46188 1.71594 6.22848 1.71594 7.02781V31.139C1.71594 31.9383 2.03348 32.7049 2.59869 33.2701C3.16391 33.8353 3.9305 34.1529 4.72984 34.1529H22.8132C23.6125 34.1529 24.3791 33.8353 24.9443 33.2701C25.5096 32.7049 25.8271 31.9383 25.8271 31.139V13.0556" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.7992 17H7.74365" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.7992 22H7.74365" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.7992 27H7.74365" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.2646 12H9.25072H7.74377" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23.2563 1H29.2841V7.02779" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20.2426 10.0417L29.2842 1" stroke="#2961D2" stroke-width="1.95017" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Dispatch Report</p>
        <p class="pt-2 text !text-[14px] text-center">Are you sure? You want to dispatch report manually?</p>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="border border-gray-500 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('modal-dispatch-report-v2')"
          >
            <template v-slot:label>Cancel</template>
          </Button>
          <Button
            id="login-button"
            type="button"
            class="text-white bg-[#2560D7]"
            buttonClass="btn-lg"
            @click.prevent="dispatchreports()"
          >
            <template v-if="!loader" v-slot:label>Dispatch</template>
            <template v-if="loader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['dispatchReportId'],
  data () {
    return {
      loader: false
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  computed: {
    ...mapGetters(['getReports', 'getReportsLoaders'])
  },
  methods: {
    ...mapActions([
      'dispatchManualReports',
    ]),
    async dispatchreports () {
      this.loader = true
      await this.dispatchManualReports(this.dispatchReportId)
      this.loader = false
      this.$bvModal.hide('modal-dispatch-report-v2')
    },
  }
}
</script>
<style lang="less">
.dispatch_report_model {
  .modal-dialog {
    max-width: 26.5rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }
  .modal-body {
    padding: 0 !important;
  }
}

</style>
